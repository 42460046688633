import React from "react"
import { graphql, Link } from "gatsby"
import Title from "../components/Title"
import NavigationLink from "../components/NavigationLink"
import pageStyles from "../pages/page.module.scss"
import styles from "./service-category.module.scss"

const ServiceCategoryTemplate = ({ data }) => {
  console.log(data)
  const {
    main: {
      frontmatter: { title },
      fields: { slug: catSlug },
    },
    items,
  } = data

  return (
    <div className={pageStyles.container}>
      <div className={pageStyles.headBar} />

      <div className={pageStyles.titleContainer}>
        <Title>{title}</Title>
        <NavigationLink to="/servicios" text="Explora todos los servicios" />
      </div>

      <div className={styles.itemsContainer}>
        {items.edges.map(item => (
          <Link
            to={`/servicios/${catSlug}/${item.node.fields.slug}`}
            className={styles.itemWrapper}
          >
            <img
              src={item.node.frontmatter.featured}
              alt={item.node.frontmatter.title}
            />
            <h2>{item.node.frontmatter.title}</h2>
          </Link>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  query ServicesByCategory($slug: String!, $category: String!) {
    main: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    items: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          frontmatter {
            featured
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ServiceCategoryTemplate
