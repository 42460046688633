import React from "react"
import { Link } from "gatsby"
import styles from "./navigationLink.module.scss"

const NavigationLink = ({ to, text }) => (
  <Link className={styles.navigationLink} to={to}>
    {text}
  </Link>
)

export default NavigationLink
